/* Panel principal */
.ingredients-panel {
    background-color: #ffffff; /* Fondo blanco */
    border: 1px solid #0077b5; /* Borde azul */
    border-radius: 8px; /* Bordes redondeados */
    padding: 16px; /* Espaciado interno */
    margin: 16px auto; /* Margen centrado */
    max-width: 400px; /* Ancho máximo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    text-align: left; /* Texto alineado a la izquierda */
}

/* IngredientsPanel.css */
.ingredients-panel {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.reponer {
    margin-top: 10px;
}

.reponer-item {
    cursor: pointer;
    padding: 8px;
    background-color: #e0f7fa;
    border-radius: 5px;
    margin-bottom: 8px;
    transition: background-color 0.3s;
}

.reponer-item:hover {
    background-color: #4dd0e1;
}

.sub-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 250px;
}

.sub-menu h4 {
    margin-bottom: 10px;
}

.ingredients-panel {
    position: relative; /* Importante para que el submenú se posicione sobre este contenedor */
    padding: 20px;
}

.reponer-item {
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    margin: 5px 0;
    border-radius: 4px;
}

.sub-menu {
    position: absolute; /* Asegura que se posicione sobre el contenedor */
    top: 50%; /* Posiciona verticalmente en el centro */
    left: 50%; /* Posiciona horizontalmente en el centro */
    transform: translate(-50%, -50%); /* Para centrarlo realmente */
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Da un poco de sombra */
    z-index: 10; /* Asegura que esté encima de otros elementos */
    width: 300px; /* Puedes ajustar el tamaño */
}

.sub-menu h4 {
    margin: 0 0 10px;
}

.sub-menu input {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.sub-menu button {
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.sub-menu button:hover {
    background-color: #45a049;
}

.sub-menu button:last-child {
    background-color: #f44336;
}

.sub-menu button:last-child:hover {
    background-color: #e53935;
}


.sub-menu input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.sub-menu button {
    width: 100%;
    padding: 10px;
    background-color: #00796b;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 5px;
    transition: background-color 0.3s;
}

.sub-menu button:hover {
    background-color: #004d40;
}


/* Título principal */
.ingredients-panel h3 {
    color: #0077b5; /* Azul principal */
    font-size: 1.5em; /* Tamaño del título */
    margin-bottom: 12px; /* Espaciado inferior */
    text-align: center; /* Centrado */
}

/* Sección de ingredientes a reponer */
.reponer {
    background-color: #fff5f5; /* Fondo ligeramente rojo */
    border: 1px solid #ff4d4f; /* Borde rojo */
    border-radius: 8px; /* Bordes redondeados */
    padding: 12px; /* Espaciado interno */
    margin-bottom: 16px; /* Espaciado con la siguiente sección */
}

/* Título de la sección de reponer */
.reponer strong {
    color: #ff4d4f; /* Rojo de advertencia */
    font-size: 1.1em;
    margin-bottom: 6px;
    display: block;
}

/* Elementos de la lista de reponer */
.reponer-item {
    background-color: #fff1f1; /* Fondo más claro para los ítems */
    border: 1px solid #ff4d4f; /* Borde rojo */
    border-radius: 4px;
    margin: 4px 0;
    padding: 8px 12px;
    color: #000000; /* Texto negro */
    font-size: 1em;
}

/* Hover para ingredientes a reponer */
.reponer-item:hover {
    background-color: #ff4d4f; /* Fondo rojo en hover */
    color: #ffffff; /* Texto blanco en hover */
    cursor: pointer;
}

/* Sección de ingredientes válidos */
.ingredients-panel div {
    margin-bottom: 12px;
}

/* Título de sección (válidos) */
.ingredients-panel strong {
    display: block;
    color: #0077b5;
    font-size: 1.1em;
    margin-bottom: 6px;
}

/* Lista de ingredientes válidos */
.ingredients-panel ul {
    list-style-type: none; /* Sin viñetas */
    padding: 0;
}

/* Elementos de la lista de válidos */
.ingredients-panel li {
    background-color: #f0f8ff; /* Azul claro */
    border: 1px solid #0077b5; /* Borde azul */
    border-radius: 4px;
    margin: 4px 0;
    padding: 8px 12px;
    color: #000000;
    font-size: 1em;
}

/* Hover para válidos */
.ingredients-panel li:hover {
    background-color: #0077b5; /* Fondo azul */
    color: #ffffff; /* Texto blanco */
    cursor: pointer;
}
