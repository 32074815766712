.policies-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.policies-container h1 {
    text-align: center;
    color: #1e1e1e;
}

.policies-container ol {
    padding-left: 20px;
}

.policies-container li {
    margin-bottom: 10px;
}

.policies-container p {
    margin-top: 20px;
    text-align: center;
    font-style: italic;
}
