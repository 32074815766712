/* Estilo principal para el contenedor de la lista de tragos */
.drink-list {
    background-color: #ffffff; /* Fondo blanco */
    border: 1px solid #0077b5; /* Borde azul */
    border-radius: 8px; /* Esquinas redondeadas */
    padding: 16px; /* Espaciado interno */
    margin: 16px auto; /* Margen centrado */
    max-width: 400px; /* Ancho máximo */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra ligera */
    text-align: center; /* Centrar el contenido */
}

/* Título de la lista */
.drink-list h3 {
    color: #0077b5; /* Azul principal */
    font-size: 1.5em; /* Tamaño del texto */
    margin-bottom: 12px; /* Espaciado inferior */
    font-weight: bold; /* Texto en negrita */
}

/* Lista de tragos */
.drink-list ul {
    list-style-type: none; /* Quitar viñetas */
    padding: 0; /* Eliminar padding predeterminado */
}

/* Elementos individuales de la lista */
.drink-list li {
    background-color: #f0f8ff; /* Azul muy claro para contraste */
    border: 1px solid #0077b5; /* Borde azul */
    border-radius: 4px; /* Esquinas ligeramente redondeadas */
    margin: 6px 0; /* Espaciado entre elementos */
    padding: 8px 12px; /* Espaciado interno */
    color: #000000; /* Texto negro */
    font-size: 1em; /* Tamaño estándar del texto */
    transition: background-color 0.3s ease; /* Transición suave */
}

/* Efecto hover para los elementos de la lista */
.drink-list li:hover {
    background-color: #0077b5; /* Fondo azul al pasar el ratón */
    color: #ffffff; /* Texto blanco en hover */
    cursor: pointer; /* Cursor interactivo */
}
