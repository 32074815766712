/* Contenedor principal de login */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  box-sizing: border-box;
}

/* Formulario de login */
.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #0077b5; /* Color principal de la marca */
}

/* Estilos de los grupos de inputs */
.input-group {
  margin-bottom: 20px;
}

.input-group label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
  display: block;
}

.input-group input {
  width: calc(100% - 20px);
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Estilo del botón de submit */
.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #0077b5; /* Color principal de la marca */
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #005f8e; /* Un tono más oscuro de azul para hover */
}

/* Contenedor del spinner */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Mensaje de error */
.error-message {
  color: red;
  margin-bottom: 15px;
  text-align: center;
}

/* Estilo del texto y enlaces dentro del formulario */
.login-form p {
  text-align: center;
  margin-top: 15px;
  color: #666;
}

.login-form p a {
  color: #0077b5; /* Color principal de la marca */
  text-decoration: none;
}

.login-form p a:hover {
  text-decoration: underline;
}
