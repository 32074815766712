.semana-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    text-align: left;
    transition: box-shadow 0.3s ease-in-out;
}

.semana-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.semana-card h5 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #0077b5;
}

.semana-card p, .semana-card li {
    font-size: 0.9rem;
    margin: 5px 0;
    color: #555;
}

.semana-card ol {
    margin-top: 10px;
    padding-left: 20px;
}

.semana-card ol li {
    font-size: 0.9rem;
    margin-bottom: 5px;
    color: #444;
}

.semana-card ul {
    margin-top: 10px;
    list-style-type: disc;
    padding-left: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.pagination button {
    background-color: #0077b5;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 0.9rem;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination span {
    font-size: 0.9rem;
    margin: 0 10px;
}
