/* Estilo para el Footer */
.footer {
    background-color: #0077b5; /* Azul brillante para el fondo */
    padding: 20px;
    text-align: center;
    color: #ffffff; /* Texto blanco */
}

.footer p {
    margin: 0;
    font-size: 14px;
}

.footer-links {
    list-style: none;
    padding: 0;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.footer-links li {
    display: inline;
}

.footer-links a {
    color: #ffffff; /* Blanco para los enlaces */
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #000000; /* Negro al pasar el cursor */
}
