/* Contenedor principal */
.ventas-container {
    padding: 20px;
    margin: auto;
    max-width: 1200px;
    background-color: #ffffff; /* Fondo blanco */
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    transition: box-shadow 0.3s ease;
}

.ventas-container {
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.ventas-header h2 {
    margin-bottom: 10px;
}

.ventas-resumen {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.ventas-resumen h4 {
    margin: 5px 0;
}

.ventas-menu {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 1.5rem 0;
}

.menu-button {
    padding: 0.5rem 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #e0e0e0;
    font-weight: bold;
}

.menu-button:hover {
    background-color: #b0bec5;
}

.menu-button.active {
    background-color: #0077b5;
    color: white;
}


.chart-container {
    margin-bottom: 30px;
    text-align: center;
}

.ventas-detalle {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.venta-card {
    border: 1px solid #ddd;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.page-arrow {
    cursor: pointer;
    font-size: 16px;
}

.page-number {
    font-size: 16px;
}


.ventas-container:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
}

/* Encabezado */
.ventas-header {
    text-align: center;
    margin-bottom: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.page-arrow {
    font-size: 16px;
    cursor: pointer;
    color: #0077b5; /* Color representativo de la marca */
    padding: 0 15px;
    transition: color 0.3s;
}

.page-arrow:hover {
    color: #000000; /* Color negro para el hover */
}

.page-number {
    font-size: 16px;
    margin: 0 10px;
    color: #333;
}

.ventas-header h2 {
    font-size: 2rem;
    color: #0077b5; /* Color representativo de la marca */
    margin-bottom: 10px;
}

/* Detalle de las ventas */
.ventas-detalle {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

/* Tarjeta de venta */
.venta-card {
    background-color: #ffffff;
    padding: 15px;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.venta-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.venta-card h5 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #444;
    text-align: right;
    background-color: #e3f2fd; /* Fondo azul claro */
    padding: 5px 10px;
    border-radius: 5px;
    display: inline-block;
    font-style: italic;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.venta-card p {
    margin: 8px 0;
    font-size: 0.95rem;
    color: #555;
}

.venta-card strong {
    color: #333;
}

/* Estilos para el botón de retroceso */
.back-button {
    display: inline-flex;
    width: 100px;
    align-items: center;
    margin-bottom: 20px;
    padding: 8px 15px;
    font-size: 16px;
    background-color: #000000; /* Fondo negro */
    color: #ffffff; /* Texto blanco */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.back-button:hover {
    background-color: #333; /* Fondo gris oscuro para hover */
    transform: scale(1.05);
}

.back-button:focus {
    outline: none;
}

.back-button:before {
    content: '←';
    font-size: 18px;
    font-weight: bold;
}

/* Resumen de ventas */
.ventas-resumen h4 {
    font-size: 1.2rem;
    margin: 5px 0;
    color: #555;
}

.ventas-resumen h4 span {
    font-weight: bold;
    color: #0077b5; /* Color representativo de la marca */
}

/* Estilos para las flechas de la paginación */
.page-arrow:hover {
    color: #0077b5; /* Flechas con color de marca */
}

/* Diseño responsivo */
@media (max-width: 768px) {
    .ventas-header h2 {
        font-size: 1.5rem;
    }

    .ventas-resumen h4 {
        font-size: 1rem;
    }

    .venta-card {
        padding: 12px;
    }
}

@media (max-width: 350px) {
    .ventas-container {
        padding: 10px;
    }

    .ventas-header h2 {
        font-size: 1.2rem;
    }

    .ventas-resumen h4 {
        font-size: 0.9rem;
    }

    .venta-card p {
        font-size: 0.85rem;
    }
}
