.sales-by-date {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 0 auto;
}

.sales-by-date h3 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.sales-by-date h4 {
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
}

.sales-by-date p {
    font-size: 14px;
    margin: 5px 0;
}

.sales-by-date div {
    margin-bottom: 20px;
}

.sales-by-date .chart-container {
    width: 100%;
    height: 400px;
}
