.details-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.Cards_containers {
  margin: 30px;
}

.Cards_elements {
  border: 1px solid green;
  border-radius: 35px;
  box-shadow: 0px 0px 30px 7px black;
  padding: 20px;
  margin: 15px;
}

h1 {
  text-align: center;
  color: #0077b5; /* Color principal de la marca */
  font-size: 2.5em;
  margin-bottom: 20px;
  font-weight: bold;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid teal;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.floating-input {
  position: relative;
  margin: 10px 0;
}
.floating-input input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.floating-input button {
  margin-left: 5px;
}

h2 {
  color: #0077b5; /* Color principal de la marca */
  text-align: left;
  font-size: 1.8em;
  margin-bottom: 10px;
}

h3 {
  color: #34495e;
  font-size: 1.2em;
  margin: 5px 0;
}

button {
  background-color: #0077b5; /* Color principal de la marca */
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 1.1em;
  margin-top: 15px;
  width: 100%;
  text-align: center;
}

button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #005f8e; /* Un tono más oscuro para hover */
  transform: translateY(-2px);
}

.tragos-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.trago-card {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.trago-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.1);
}

.trago-card h3 {
  color: #0077b5; /* Color principal de la marca */
  font-size: 1.4em;
}

.trago-card p {
  color: #34495e;
  font-size: 1.1em;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #0077b5; /* Color principal de la marca */
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 20px;
}

.message-container h3 {
  font-size: 1.5em;
  color: #e74c3c; /* Rojo para mensajes de error */
}
