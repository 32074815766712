/* Navbar general */
.navbar {
    background-color: #0077b5; /* Azul brillante para el fondo */
    padding: 5px 15px; /* Navbar compacto */
    display: flex;
    justify-content: space-between; /* Mantiene los elementos en extremos opuestos */
    align-items: center; /* Centra verticalmente */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    height: 50px; /* Altura más pequeña */
    position: relative;
}

/* Logo del navbar */
.navbar-logo {
    display: flex;
    align-items: center;
}

/* El texto animado del logo */
.logo-animado {
    font-family: 'Orbitron', sans-serif; /* Tipografía futurista */
    font-size: 2rem;
    color: #ffffff;
    display: inline-block;
    animation: fadeInLetters 2s ease forwards;
}

/* Estilo del menú hamburguesa */
.hamburger-menu {
    display: none; /* Solo visible en pantallas pequeñas */
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    position: absolute; /* Posicionamos el menú */
    right: 15px; /* Lo alineamos a la derecha */
    top: 10px; /* Ajustamos la distancia superior */
    z-index: 10; /* Aseguramos que quede encima */
}

.hamburger-menu .bar {
    width: 25px;
    height: 3px;
    background-color: #ffffff;
    border-radius: 5px;
    transition: all 0.3s ease;
}

/* Enlaces del navbar */
.navbar-links {
    list-style: none;
    display: flex;
    gap: 15px;
}

.navbar-links li {
    margin: 0;
}

.navbar-links a {
    color: #ffffff; /* Blanco para los enlaces */
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    transition: color 0.3s ease;
}

.navbar-links a:hover {
    color: #000000; /* Negro al pasar el cursor */
}

/* Botón de logout */
.logout-button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.logout-button:hover {
    color: #000000;
}

/* Comportamiento del menú en dispositivos pequeños */
@media (max-width: 768px) {
    .navbar {
        height: auto; /* Ajuste automático de altura */
    }

    .navbar-links {
        flex-direction: column;
        align-items: flex-start;
        display: none; /* Oculta el menú inicialmente */
        width: 100%;
    }

    .navbar-links.open {
        display: block; /* Muestra el menú cuando está abierto */
    }

    .hamburger-menu {
        display: flex; /* Mostrar el menú hamburguesa en móviles */
    }

    .hamburger-menu.open .bar:nth-child(1) {
        transform: rotate(45deg);
        position: relative;
        top: 6px;
    }

    .hamburger-menu.open .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger-menu.open .bar:nth-child(3) {
        transform: rotate(-45deg);
        position: relative;
        top: -6px;
    }
}
