/* Estilo principal para el contenedor */
.sales-analytics {
    padding: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 800px;
    margin: 20px auto;
    font-family: 'Roboto', sans-serif;
}

/* Título */
.sales-analytics h3 {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    color: #4CAF50;
    margin-bottom: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

/* Estilo para las métricas */
.metrics p {
    font-size: 16px;
    color: #444;
    margin: 6px 0;
    line-height: 1.5;
    font-weight: 400;
}

/* Añadir un pequeño efecto a las métricas */
.metrics p:hover {
    color: #4CAF50;
    transition: color 0.3s ease;
}

/* Contenedor del gráfico */
.chart-container {
    width: 100%;
    height: 350px;
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Contenedor de las métricas adicionales */
.metrics {
    font-family: 'Roboto', sans-serif;
    margin-top: 20px;
    padding: 0 15px;
}

/* Estilo para cada métrica */
.metric {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
    color: #444;
}

/* Barra de progreso para representar gráficamente los valores */
.metric .progress-bar {
    width: 100%;
    background-color: #ddd;
    border-radius: 10px;
    height: 12px;
    margin-top: 5px;
}

.metric .progress {
    height: 100%;
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
}

/* Colores para las barras */
.progress.machine {
    background-color: #4CAF50; /* Verde para la máquina */
}

.progress.drink {
    background-color: #2196F3; /* Azul para el trago */
}

.progress.recommendation {
    background-color: #FFC107; /* Amarillo para la recomendación */
}

/* Mejora de estilo para las métricas */
.metrics p strong {
    font-weight: bold;
    color: #333;
}

/* Para los números de las métricas */
.sales-analytics p span {
    font-size: 22px;
    font-weight: bold;
    color: #4CAF50;
}

/* Responsivo para pantallas pequeñas */
@media (max-width: 768px) {
    .sales-analytics {
        padding: 15px;
    }
    .chart-container {
        height: 300px;
    }
}
