.machines-container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido horizontalmente */
}

h2 {
    color: #0077b5;
    text-align: center;
    margin-bottom: 20px;
}

.machines-list {
    display: flex;
    flex-wrap: wrap; /* Permite que los elementos se ajusten a varias filas si es necesario */
    justify-content: center; /* Centra los elementos horizontalmente */
    gap: 20px;
}

.machine-card {
    position: relative;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    overflow: hidden;
    padding: 15px;
    text-align: center;
    width: 250px; /* Ajusta el tamaño de las tarjetas */
    margin-bottom: 20px; /* Espacio entre las tarjetas */
}

.machine-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.machine-info {
    margin-bottom: 15px;
}

.machine-mac, .machine-owner, .machine-count {
    color: #000000;
    font-size: 0.9em;
    margin: 8px 0;
    word-wrap: break-word;
}

.machine-mac span, .machine-owner span, .machine-count span {
    color: #0077b5;
    font-weight: bold;
}

.status-indicator {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #e0e0e0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.status-indicator.online {
    background-color: #0077b5;
}

.status-indicator.offline {
    background-color: #000000;
}

/* Hover para el estado azul */
.status-indicator.online:hover {
    transform: scale(1.2);
    background-color: #005983;
}

/* Hover para el estado negro */
.status-indicator.offline:hover {
    transform: scale(0.8);
    background-color: #333333;
}

.status-indicator:hover {
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.btn-drinks {
    display: inline-block;
    padding: 20px;
    margin: 3px;
    margin-top: 15px;
    background-color: #0077b5;
    color: #ffffff;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.btn-drinks:hover {
    background-color: #005983;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

/* Adaptabilidad */
@media (max-width: 600px) {
    .machines-container {
        padding: 15px;
    }

    .machine-card {
        padding: 10px;
    }

    .machine-mac, .machine-owner, .machine-count {
        font-size: 0.85em;
    }

    .btn-drinks {
        font-size: 0.9em;
        padding: 8px 12px;
    }

    .status-indicator {
        width: 15px;
        height: 15px;
        top: 8px;
        left: 8px;
    }
}

@media (max-width: 350px) {
    .machine-card {
        padding: 8px;
    }

    .machine-mac, .machine-owner, .machine-count {
        font-size: 0.8em;
    }

    .btn-drinks {
        font-size: 0.85em;
        padding: 6px 10px;
    }

    .status-indicator {
        width: 12px;
        height: 12px;
        top: 5px;
        left: 5px;
    }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
}

.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border-left-color: #0077b5;
    animation: spin 1s ease infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
