.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa; /* Color más suave */
  padding: 20px;
  box-sizing: border-box;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  padding: 40px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Sombra más suave */
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #2c3e50; /* Color oscuro pero suave para el texto */
}

.input-group {
  margin-bottom: 20px;
}

.input-group label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #34495e; /* Color de texto gris oscuro */
  display: block;
}

.input-group input {
  width: calc(100% - 20px);
  padding: 12px;
  border: 1px solid #ddd; /* Borde gris suave */
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f0f3f4; /* Fondo muy claro */
  color: #2c3e50; /* Color de texto oscuro */
}

.submit-button {
  width: 100%;
  padding: 12px;
  background-color: #36d7b7; /* Color de fondo principal */
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #2bb3a3; /* Color hover un poco más oscuro */
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-message {
  color: #e74c3c; /* Color rojo más suave para el mensaje de error */
  margin-bottom: 15px;
  text-align: center;
}

.login-form p {
  text-align: center;
  margin-top: 15px;
  color: #7f8c8d; /* Color gris suave */
}

.login-form p a {
  color: #36d7b7; /* Color verde suave */
  text-decoration: none;
}

.login-form p a:hover {
  text-decoration: underline;
}
