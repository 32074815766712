.week-ventas-container {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.week-title {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #0077b5;
    text-align: center;
}

.resumen-semanal {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.resumen-semanal h4 {
    font-size: 1rem;
    margin: 5px 0;
}

.resumen-semanal span {
    font-weight: bold;
    color: #0077b5;
}

.semanas-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 15px;
    margin-top: 20px;
}

.no-data {
    text-align: center;
    color: #ff5252;
    font-size: 1.2rem;
}
