.venta-card {
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    text-align: left;
    transition: box-shadow 0.3s ease-in-out;
}

.venta-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.venta-card h5 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #0077b5;
}

.venta-card p {
    font-size: 0.9rem;
    margin: 5px 0;
}

.venta-card strong {
    color: #444;
}
