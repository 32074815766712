/* Paleta de colores */
:root {
    --color-primary: #0077b5;  /* Azul brillante */
    --color-primary-dark: #005f8f;  /* Azul más oscuro para hover */
    --color-background: #f8f9fa;  /* Fondo suave */
    --color-background-light: #ffffff;  /* Fondo blanco */
    --color-border: #ddd;  /* Borde suave */
    --color-text: #333;  /* Texto oscuro */
    --color-text-light: #666;  /* Texto más suave */
    --color-error: #ff4d4d;  /* Color de error */
    --color-home-background: #f0f0f0; /* Fondo suave para home */
}

.dashboard-container {
    padding: 20px;
    text-align: center;
    background-color: var(--color-background); /* Fondo suave */
    border-radius: 8px; /* Bordes redondeados para un toque moderno */
}

/* Estilo principal del contenedor del Dashboard */
.dashboard-container {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px auto;
    max-width: 1200px;
}

/* Título del Dashboard */
.dashboard-container h1 {
    font-size: 2em;
    color: #0077b5;
    text-align: center;
    margin-bottom: 20px;
}

/* Contenedor para DrinkList e IngredientsPanel */
.dashboard-content {
    display: flex; /* Alinea los componentes horizontalmente */
    justify-content: space-between; /* Espacio entre los componentes */
    align-items: flex-start; /* Alinea los componentes en la parte superior */
    gap: 20px; /* Espacio entre los componentes */
    margin-top: 20px;
}

/* Estilos individuales para DrinkList e IngredientsPanel */
.dashboard-content > * {
    flex: 1; /* Ambos componentes ocupan el mismo espacio */
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9fafc;
}

/* Asegurar que DrinkList y IngredientsPanel no se desborden */
@media (max-width: 768px) {
    .dashboard-content {
        flex-direction: column; /* Se apilan en pantallas pequeñas */
    }
}


.machines-list {
    margin-top: 20px;
}

.machine-details {
    border: 1px solid var(--color-border); /* Borde suave y claro */
    padding: 15px; /* Aumento de padding para mayor espacio */
    margin-bottom: 20px; /* Distancia entre elementos */
    background-color: var(--color-background-light); /* Fondo blanco para los detalles */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
    border-radius: 8px; /* Bordes redondeados */
}

.download-button {
    margin-top: 20px;
    padding: 12px 25px; /* Aumento de padding para hacerlo más grande */
    background-color: var(--color-primary); /* Azul brillante */
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: var(--color-primary-dark); /* Azul más oscuro para hover */
}
